import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "~/components/layout"
import SEO from "~/components/seo"
import Calendar from "~/components/calendar-icon"

import calendarIcon from "~/images/icon_calendar.svg"
import calendarDarkIcon from "~/images/icon_calendar_dark.svg"
import giftIcon from "~/images/icon_gift.png"
import newsIcon from "~/images/icon_news.png"
import useDarkMode from "use-dark-mode"
import RoundCheckBox from "~/components/round-checkbox"

const Wrapper = styled.div`
  width: 100%;
  max-width: 734px;
  margin: 35px auto 0;
`

const Tool = styled.div`
  width: 100%;
  max-width: 334px;
  margin: 0 auto 16px;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 10px;

  &.-dark {
    > ul {
      > li {
        color: #fff;
      }
    }
  }

  > ul {
    > li {
      font-size: 18px;
      color: #6f6f6f;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 26px;
      }
    }
  }

  > div {
    > img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 769px) {
    width: 70%;

    > ul {
    > li {
      font-size: 14px;
      color: #6f6f6f;
      cursor: pointer;
    }
  }
  }
`

const CardList = styled.div`
  padding: 10px 45px 10px 45px;

  @media screen and (min-width: 1023px) {
    &:after {
      content: "";
      width: 1px;
      height: 100%;
      background: #c9c9c9;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    padding: 0px;
  }
`

const Duration = styled.div`
  font-size: 16px;
`

const CardContent = styled.div`
  /* background: #fff; */
  background: ${props => props.bgSecondary};
  border: 0.5px solid #c9c9c9;
  border-radius: 6px;

  > img {
    width: 124px;
    height: 124px;
    margin: 0 20px 0 20px;
  }

  > div {
    font-size: 14px;
    padding-right: 6px;

    .tag {
      font-size: 8px;
      padding-left: 20px;
      line-height: 16px;
      margin: 12px 0 5px;

      &.event {
        color: #774afe;
        background: url(${giftIcon}) no-repeat;
        background-size: 16px;
      }

      &.news {
        color: #2dcaa0;
        background: url(${newsIcon}) no-repeat;
        background-size: 16px;
      }
    }

    ul {
      font-size: 10px;
      color: #6f6f6f;

      li:before {
        content: "- ";
      }
    }
  }
`

const CardLabel = styled.span`
  font-size: 12px;
  background: #13c750;
  width: 124px;
  height: 26px;
  transform: rotate(-90deg);
  left: calc(-62px + 13px);
  top: calc(62px - 13px);

  &.arrived {
    background: #774afe;
  }
`

const FilterBox = styled.div`
  border: 0.5px solid #c9c9c9;
  border-radius: 6px;
  padding: 12px 26px 26px;
  position: absolute;
  z-index: 1;
  right: 90px;
  bottom: 0;
  transform: translate(50%, 100%);
  font-size: 12px;
  min-width: 187px;
`

const EventPage = ({
  data: {
    allMarkdownRemark,
    markdownRemark: {
      frontmatter
    },
    theme,
  },
  pageContext
}) => {
  const themeColor = theme.edges[0].node.frontmatter;
  const eventList = allMarkdownRemark.edges
  .filter(item => item.node.file.split("/")[1] === pageContext.langKey)
  .map(item => item.node)
  const listCate = [
    { val: "ALL", en: "ALL", th: "ทั้งหมด", },
    { val: "NEWS", en: "UPCOMING", th: "อัพเดทเกมใหม่", },
    { val: "EVENT", en: "EVENT", th: "กิจกรรม", },
  ]
  const listMonthDate = eventList.map(
    ({ frontmatter: { end_date } }) => [
      end_date.substring(3),
    ]
  )
  const list = [...new Set([].concat.apply([], [...listMonthDate]))]
  const [currentCate, setCurrentCate] = useState("ALL")
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [listSelected, setListSelected] = useState([...list])
  const { value } = useDarkMode(false)
  const today = new Date()

  const handleCheck = ({ target: { className } }) => {
    if (className === "ALL") {
      if (list.length === listSelected.length) {
        setListSelected([])
      } else {
        setListSelected([...list])
      }
    } else {
      const newSelected = listSelected.includes(className)
        ? listSelected.filter(item => item !== className)
        : [...listSelected, className]
      setListSelected([...newSelected])
    }
  }

  const handleCalendar = () => {
    setIsShowFilter(!isShowFilter)
  }
  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={frontmatter.metadata.metaTitle}
        description={frontmatter.metadata.metaDescription} />
      <Wrapper>
        <Tool
          className={`flex justify-between items-center relative ${
            value && "-dark"
          }`}
        >
          <ul className="flex">
            {listCate.map(item => (
              <li
                key={item.val}
                className={item.val === currentCate ? "font-bold" : ""}
                onClick={() => setCurrentCate(item.val)}
                style={{whiteSpace: "nowrap"}}
              >
                {item[pageContext.langKey]}
              </li>
            ))}
          </ul>
          <div>
            <Calendar
              src={value ? calendarDarkIcon : calendarIcon}
              alt=""
              handleCalendar={handleCalendar}
            />
            <FilterBox
              className={`absolute filter-event ${isShowFilter ? "block" : "hidden"}`}
            >
              <div
                style={{
                  paddingBottom: "24px",
                  borderBottom: "1px solid #eee",
                }}
              >
                <RoundCheckBox
                  text={`ALL`}
                  handleCheck={handleCheck}
                  check={listSelected.length === list.length}
                  typeClass={`ALL`}
                  isFront={true}
                  isSome={
                    listSelected.length < list.length && listSelected.length > 0
                  }
                />
              </div>
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display:
                          index !== 0 &&
                          item.slice(-4, item.length) ===
                            list[index - 1].slice(-4, list[index - 1].length)
                            ? "none"
                            : "block",
                        marginTop: "24px",
                      }}
                    >
                      {item.slice(-4, item.length)}
                    </div>
                    <div style={{ marginTop: "24px" }}>
                      <RoundCheckBox
                        text={item.slice(0, -5)}
                        handleCheck={handleCheck}
                        check={listSelected.some(data => data === item)}
                        typeClass={item}
                        isFront={true}
                      />
                    </div>
                  </div>
                )
              })}
            </FilterBox>
          </div>
        </Tool>
        {eventList
          .filter(item =>
            currentCate === "ALL"
              ? item
              : item.frontmatter.type.toUpperCase() === currentCate
          )
          .filter(
            ({ frontmatter: { start_date, end_date } }) =>
              listSelected.includes(start_date.substring(3, start_date.length)) ||
              listSelected.includes(end_date.substring(3, end_date.length))
          )
          .map((item, index) => (
            <CardList
              className="relative grid-cols-2 lg:grid gap-x-14"
              key={index}
            >
              <div style={{ display: index % 2 ? "block" : "none" }} />
              <div>
                <Duration className="font-bold">
                  {item.frontmatter.end_date.substring(3)}
                </Duration>
                <CardContent className="relative flex overflow-hidden card-content"
                  bgSecondary={value ? themeColor.dark_theme.background_color.secondary : themeColor.light_theme.background_color.secondary}
                >
                  <img src={`/${item.frontmatter.thumbnail}`} alt="" />
                  <CardLabel
                    className={`absolute text-white flex justify-center items-center ${
                      today.toISOString() >=
                        new Date(item.frontmatter.start_date).toISOString() &&
                      "arrived"
                    }`}
                  >
                    {today.toISOString() >=
                    new Date(item.frontmatter.start_date).toISOString()
                      ? "New Arrived"
                      : "Coming Soon"}
                  </CardLabel>
                  <div>
                    <div
                      className={`tag ${item.frontmatter.type.toLowerCase()}`}
                    >
                      {item.frontmatter.type}
                    </div>
                    <div className="font-bold">{item.frontmatter.title}</div>
                    <div
                      className="detail"
                      dangerouslySetInnerHTML={{
                        __html: item.html,
                      }}
                    ></div>
                  </div>
                </CardContent>
              </div>
              <div style={{ display: index % 2 ? "none" : "block" }} />
            </CardList>
          ))}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query EventPageTemplate($langKey: String!) {
    allMarkdownRemark(
      filter: {
        collection: { eq: "event" }
        frontmatter: { is_active: { eq: true } }
      }
      sort: { fields: frontmatter___start_date, order: DESC }
    ) {
      edges {
        node {
          file
          html
          frontmatter {
            type
            title
            start_date(formatString: "DD MMMM YYYY")
            end_date(formatString: "DD MMMM YYYY")
            thumbnail
          }
        }
      }
    }

    markdownRemark(
      frontmatter: {templateKey: {eq: "event"}}
      fields: {langKey: {eq: $langKey}}
    ) {
      id
      frontmatter {
        metadata {
          metaDescription
          metaTitle
        }
      }
      fields {
        langKey
      }
    }

    theme: allMarkdownRemark(filter: {collection: {eq: "theme"}}) {
      edges {
        node {
          file
          frontmatter {
            light_theme {
              background_color {
                primary
                secondary
                third
                fourth
              }
              font_color {
                primary
                secondary
                third
              }
            }
            dark_theme {
              background_color {
                primary
                secondary
                third
                fourth
              }
              font_color {
                primary
                secondary
                third
              }
            }
          }
        }
      }
    }

    iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
        edges {
          node {
            id
            frontmatter {
              question
              sort
              sun
              gift
              play
            }
          }
        }
      }
  }
`

export default EventPage
