import React from "react"
import PropTypes from "prop-types"

const CalendarIcon = ({ src, handleCalendar }) => (
  <img src={src} alt="calendar" onClick={handleCalendar} />
)

CalendarIcon.propTypes = {
  src: PropTypes.string,
  handleCalendar: PropTypes.func,
}

export default CalendarIcon
